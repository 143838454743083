<template>
<div>

  <DashboardHeader></DashboardHeader>

  <v-row class="pa-sm-5">

    <v-col cols="12">

      <p class="text-left py-0 my-0">
        <small>- Résumé des réservations et commandes <b>payées et envoyées</b>(status = SENT)</small>
      </p>

    </v-col>

    <v-col cols="12" sm="6" md="4" lg="4">
      <v-card outlined elevation="0">
        <v-card-title class="">CA Boutique :  {{ totalR }}€</v-card-title>
        <ChartSold :height="130" name="resa"></ChartSold> 
        <v-data-table dense
          :headers="headersResa" 
          :items="reservations"
          :items-per-page="5">
          <template v-slot:item.updated="{ item }">
            {{ getStrDate(item.updated) }}
          </template>
          <template v-slot:item.totalPrice="{ item }">
            {{ item.totalPrice }}€
          </template>
        </v-data-table>
      </v-card>
      
      <v-card outlined elevation="0" class="mt-5">
        <v-card-title class="">CA Commande :  {{ totalC }}€</v-card-title>
        <ChartSold :height="130" name="commande"></ChartSold> 
        <v-data-table dense
          :headers="headersCommande" 
          :items="commandes"
          :items-per-page="5"
          >
          <template v-slot:item.updated="{ item }">
            {{ getStrDate(item.updated) }}
          </template>
          <template v-slot:item.price="{ item }">
            {{ item.price }}€
          </template>
        </v-data-table>
      </v-card>
    </v-col>

    <v-col cols="12" sm="6" md="8" lg="8" class="text-center">
      <v-card outlined elevation="0">
        <v-card-title>Total chiffre d'affaire : {{ totalBenef }}€</v-card-title>
        <ChartSold :height="130" name="main"></ChartSold> 
        <v-data-table dense
          :headers="headersAll" 
          :items="all"
          >
          <template v-slot:item.price="{ item }">
            {{ item.price }}€
          </template>
          <template v-slot:item.updated="{ item }">
            {{ getStrDate(item.updated) }}
          </template>
        </v-data-table>
      </v-card>
      
    </v-col>
    
  </v-row>

</div>
</template>

<script>
import axios from 'axios'

import DashboardHeader from '@/components/admin/DashboardHeader.vue'
import ChartSold from '@/components/admin/charts/ChartSold.vue'

import core from '../../plugins/core.js'

export default {
  components: { DashboardHeader, ChartSold },
  data: () => ({
    reservations: [],
    commandes: [],
    all: [],
    totalR : 0,
    totalC : 0,
    totalBenef : 0,

    headersResa: [{ text: "Nom", value: "name" },
                  { text: "Prix", value: "totalPrice" },
                  { text: "Vendu le", value: "updated" },
            ],
    headersCommande: [{ text: "Nom", value: "name" },
                      { text: "Prix", value: "price" },
                      { text: "Vendu le", value: "updated" },
            ],
    headersAll: [ { text: "Nom", value: "name" },
                  { text: "Prix", value: "price" },
                  { text: "Type", value: "type" },
                  { text: "Vendu le", value: "updated" },
            ],
    
  }),
  async mounted(){
    this.loadMainData()
  },
  methods: {
    async loadMainData(){
      await this.loadResas()
      await this.loadCommandes()
      this.calculateBenefices()
      this.initChart()
    },
    async loadResas(){
      let res = await axios.post('/admin/query-entities', {
                        entityType: "reservation",
                        query: { status: { '$in' : ['SENT'] }  },
                        sort: 'status'
                      })
      if(res.data.error == false){
        this.reservations = res.data.entities
      }
    },
    async loadCommandes(){
      let res = await axios.post('/admin/query-entities', {
                        entityType: "commande",
                        query: { status: { '$in' : ['SENT'] }  },
                        sort: 'status'
                      })
      if(res.data.error == false){
        this.commandes = res.data.entities
      }
    },

    calculateBenefices(){
      this.totalR = 0
      this.totalC = 0
      this.reservations.forEach((resa) => { this.totalR += resa.totalPrice })
      this.commandes.forEach((commande) => { this.totalC += commande.price })
      this.totalBenef = this.totalR + this.totalC
    },

    initChart(){
      this.$root.$emit('rebootChartSold', 'main')  
      this.$root.$emit('rebootChartSold', 'resa')  
      this.$root.$emit('rebootChartSold', 'commande')  
      this.$root.$emit('addToChartSold', 0, 'D0', 'main')  
      this.$root.$emit('addToChartSold', 0, 'D0', 'resa')  
      this.$root.$emit('addToChartSold', 0, 'D0', 'commande') 

      this.all = []
      this.reservations.forEach((resa) => { 
        let date = new Date(resa.updated)
        let label = date.getDay() + "/" + date.getMonth() + "/" + date.getFullYear()
        if(resa.updated != null){
          //this.$root.$emit('addToChartSold', resa.totalPrice, label, 'main') 
          resa.type = "resa"
          this.all.push(resa)
          this.$root.$emit('addToChartSold', resa.totalPrice, label, 'resa') 
        }
      })

      this.commandes.forEach((commande) => { console.log("commande", commande.price, commande.updated)
        let date = new Date(commande.updated)
        let label = this.getStrDate(date)
        if(commande.updated != null){
          //this.$root.$emit('addToChartSold', commande.price, label, 'main') 
          commande.type = "commande"
          this.all.push(commande)
          this.$root.$emit('addToChartSold', commande.price, label, 'commande') 
        }
      })

      this.all.sort((a, b) => a.update > b.update && 1 || -1)
      this.all.forEach((resa) => { 
        let date = new Date(resa.updated)
        let label = this.getStrDate(date)
        if(resa.totalPrice != null) this.$root.$emit('addToChartSold', resa.totalPrice, label, 'main') 
        if(resa.price != null) this.$root.$emit('addToChartSold', resa.price, label, 'main') 
      })

      this.all.forEach((resa) => { 
        resa.price = resa.price ? resa.price : resa.totalPrice
        resa.name = resa.name ? resa.name : resa.name
      })

      setTimeout(()=>{ 
        this.$root.$emit('rerenderChartSold', 'main') 
        this.$root.$emit('rerenderChartSold', 'resa') 
        this.$root.$emit('rerenderChartSold', 'commande') 
      }, 500)
      

    },

    getStrDate(d){
      return core.getStrDate(d)
    },
    nl2br: function(str, is_xhtml){
      return core.nl2br(str, is_xhtml)
    },
  },
}
</script>