var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DashboardHeader'),_c('v-row',{staticClass:"pa-sm-5"},[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"text-left py-0 my-0"},[_c('small',[_vm._v("- Résumé des réservations et commandes "),_c('b',[_vm._v("payées et envoyées")]),_vm._v("(status = SENT)")])])]),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"4"}},[_c('v-card',{attrs:{"outlined":"","elevation":"0"}},[_c('v-card-title',{},[_vm._v("CA Boutique : "+_vm._s(_vm.totalR)+"€")]),_c('ChartSold',{attrs:{"height":130,"name":"resa"}}),_c('v-data-table',{attrs:{"dense":"","headers":_vm.headersResa,"items":_vm.reservations,"items-per-page":5},scopedSlots:_vm._u([{key:"item.updated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getStrDate(item.updated))+" ")]}},{key:"item.totalPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.totalPrice)+"€ ")]}}])})],1),_c('v-card',{staticClass:"mt-5",attrs:{"outlined":"","elevation":"0"}},[_c('v-card-title',{},[_vm._v("CA Commande : "+_vm._s(_vm.totalC)+"€")]),_c('ChartSold',{attrs:{"height":130,"name":"commande"}}),_c('v-data-table',{attrs:{"dense":"","headers":_vm.headersCommande,"items":_vm.commandes,"items-per-page":5},scopedSlots:_vm._u([{key:"item.updated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getStrDate(item.updated))+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.price)+"€ ")]}}])})],1)],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"6","md":"8","lg":"8"}},[_c('v-card',{attrs:{"outlined":"","elevation":"0"}},[_c('v-card-title',[_vm._v("Total chiffre d'affaire : "+_vm._s(_vm.totalBenef)+"€")]),_c('ChartSold',{attrs:{"height":130,"name":"main"}}),_c('v-data-table',{attrs:{"dense":"","headers":_vm.headersAll,"items":_vm.all},scopedSlots:_vm._u([{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.price)+"€ ")]}},{key:"item.updated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getStrDate(item.updated))+" ")]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }